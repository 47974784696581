<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group class="login-part">
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form>
                  <h1>Connexion</h1>
                  <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                  <p class="text-muted" v-else-if="!step2">Connectez-vous pour accéder à votre ferme</p>

                  <div v-if="!step2">
                    <c-input container-class="mb-3 required-input" label="Email" placeholder="Ex: bob@gmail.com"
                             v-model="email" :state="emailState">
                      Veuillez saisir une adresse email
                    </c-input>
                    <c-input container-class="mb-2 required-input" type="password" label="Mot de passe"
                             placeholder="Votre mot de passe" v-model="password" :state="passwordState">
                      Veuillez saisir un mot de passe
                    </c-input>

                    <b-input-group class="mb-4 ml-1">
                      <b-form-checkbox v-model="remember" class="text-muted cursor-pointer"> Se souvenir de moi
                      </b-form-checkbox>
                    </b-input-group>
                  </div>

                  <div v-else>
                    <b-form-text class="mb-4" v-html="infoMessage"></b-form-text>
                    <c-input container-class="mb-2" label="Code d'authentification" placeholder="Ex: 123456"
                             v-model="code" :state="codeState">
                      Code d'authentification invalide
                    </c-input>
                    <b-form-text class="mb-5">
                      Vous n'avez pas reçu de code ?
                      <b-link block variant="primary" type="button" @click="onResendAuthCode">Cliquez ici</b-link>
                      pour renvoyer
                    </b-form-text>
                  </div>
                  <b-row>
                    <b-col cols="6">
                      <button-spinner variant="success" type="submit" class="px-4" @click="onSubmit"
                                      :fetching="fetching">
                        Valider
                      </button-spinner>
                    </b-col>
                    <b-col cols="6" class="text-right">
                      <b-button variant="link" class="px-0" to="/forgot-password">Mot de passe oublié?</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
            <b-card no-body class="text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <b-card-body class="text-center">
                <div>
                  <h2>Inscription</h2>
                  <p>
                    Vous n'avez pas encore de compte ? Inscrivez-vous en quelques clics pour bénéficier de tous les
                    avantages de l'application PIA.
                  </p>
                  <b-button variant="primary" class="active mt-3" to="/register">M'inscrire !</b-button>
                </div>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
    
  </div>
</template>

<script>
  import {Api, Auth, Regex, Toast, String} from "../../helpers";

  export default {
    name: 'Login',
    title: 'PIA - Connexion',
    data() {
      return {
        email: '',
        password: '',
        remember: false,
        submitted: false,
        error: null,
        fetching: false,
        step2: false,
        code: '',
        phoneNumber: '',
        emailAuthentication: false,
        smsAuthentication: false,
        passwordFieldType: true,
        
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    mounted(){
    },
    computed: {
      passwordFieldTypeVal() {
        return this.passwordFieldType ? 'fa fa-eye' : 'fa fa-eye-slash'
      },
      emailState() {
        return !this.submitted || Regex.isEmail(this.email) ? null : false
      },
      passwordState() {
        return !this.submitted || this.password.length > 0 ? null : false
      },
      codeState() {
        return !this.submitted || Regex.isValidPhoneConfirmationCode(this.code) > 0 ? null : false
      },
      infoMessage() {
        let msg = "Un code d'authentification vous a été envoyé par"
        if (this.emailAuthentication) {
          msg += " courriel"
          if (this.smsAuthentication)
            msg += " et par"
        }
        if (this.smsAuthentication)
          msg += ` SMS à votre numéro <strong>${this.hiddenPhoneNumber}</strong>`

        msg += ". Veuillez entrer ce code pour continuer."

        return msg
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
      hiddenPhoneNumber() {
        return String.hiddenPhoneNumber(this.phoneNumber)
      }
    },
    methods: {
      showVisibility() {
        this.passwordFieldType = !this.passwordFieldType
      },
      valid() {
        return Regex.isEmail(this.email) && this.password.length > 0
      },
      authenticateUser(user) {
        Auth.authenticateUser(user, this.remember)
        
        const to = this.$route.query.redirect
        if (to)
          this.$router.push(to)
        // verifying if it is the super admin connecting
        else if (user.isSuperAdmin)
          this.$router.push('/super-admin')
        else
          this.$router.push('/home')
      },
      login(ignoreValidation) {
        this.submitted = true
        if (!ignoreValidation && !this.valid()) return

        this.fetching = true
        Api.post('/login', {
          email: this.email,
          password: this.password,
        })
          .then(res => {
            const user = res.data.data
            if (res.data.status === 'success' && user) {
              
              if (user.emailAuthentication || user.smsAuthentication) {
                if (user.smsAuthentication)
                  this.phoneNumber = user.phoneNumber
                this.code = ''
                this.step2 = true
                this.submitted = false
                this.error = null
                this.emailAuthentication = user.emailAuthentication
                this.smsAuthentication = user.smsAuthentication
              }
              else {
                this.authenticateUser(user)
              }
            }
            else {
              this.error = res.data.error
              this.password = ''
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetching = false
          })
      },
      validCode() {
        return Regex.isValidPhoneConfirmationCode(this.code)
      },
      loginStep2() {
        this.submitted = true
        if (!this.validCode()) return

        this.fetching = true
        Api.post('/login/step2', {
          email: this.email,
          code: this.code,
        })
          .then(res => {
            const user = res.data.data
            if (res.data.status === 'success' && user) {
              this.authenticateUser(user)
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetching = false
          })
      },
      onSubmit() {
        this.step2 ? this.loginStep2() : this.login()
      },
      onResendAuthCode() {
        this.code = ''
        this.login(true)
      }
    }
  }
</script>

<style scoped>
  .app{
    background-size: 100% 100%,cover;
  }
  .login-part{
    border-radius: 5px;
    box-shadow: 0 0 40px #000;
  }
</style>
